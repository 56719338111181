//VALIDATE USER EMAIL
$.validator.addMethod("validateUserEmail", function (value) {
    var _return;
    $.ajax({
        url     : base_url + 'general/check_user_email',
        type    : 'POST',
        data    :  {"emails": value},
        async   : false,
        success : function (data) {
            try {
                var r = JSON.parse(data);
                if(r._exists == 'yes'){
                    _return = false;
                }else{
                    _return = true;
                }
            } catch (e) {
                _return = false;
            }
        },
        error: function () {
            _return = false;
        }
    });
    console.log(_return);
    return _return;
}, 'E-mail já utilizado. Faça login na sua conta existente.');