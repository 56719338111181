$.validator.addMethod( "creditcardvalid", function( value, element ) {
    value = value.replace(/\s/g,"");
    if (!/^\d{1,2}\/\d{4}$/.test(value)) return false;
    var parts = value.split('/');
    var today = new Date(); today.setHours(0,0,0,0); //normalise
    var yyyy = parseInt(parts[1],10);
    var mm = parseInt(parts[0],10);
    var expDate = new Date(yyyy,mm-1,today.getDate(),0,0,0,0);
    //if (expDate.getFullYear() != yyyy || expDate.getMonth() != (mm-1)) return false;
    if (expDate.getTime()<today.getTime()) return false; // older than today - maybe even test it is not in the too near future
    if (expDate.getFullYear()-today.getFullYear()>20) return false; // not a likely date    
    return true
}, "Please enter a valid credit card number." );
